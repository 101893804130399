import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './NewsContents.css';

const NewsContents: React.FC<{ showAll: boolean }> = ({ showAll }) => {
    const [newsData, setNewsData] = useState<Array<{ newsNumber: number, date: string, title: string, content: string, image: string[], option: string }>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1); // 現在のページ番号を管理するステートを追加
    const [upperLimit, setUpperLimit] = useState<number>(0);
    useEffect(() => {
        if (showAll) {
            setUpperLimit(10);
        } else {
            setUpperLimit(3);
        }
    }, [showAll]);
    const newsListCount: number = Math.ceil(newsData.length / upperLimit); // ニュースリストのページ数を計算

    useEffect(() => {
        fetchNewsData().then(data => setNewsData(data));
    }, []);

    async function fetchNewsData(): Promise<Array<{ newsNumber: number, date: string, title: string, content: string, image: string[], option: string }>> {
        const response: Response = await fetch(process.env.PUBLIC_URL + '/news_20240422.json',);
        const text: string = await response.text();
        const data: Array<{ newsNumber: number, date: string, title: string, content: string, image: string[], option: string }> = JSON.parse(text);
        return data;
    }

    // ページ番号に基づいて表示するニュースの範囲を計算
    const startIndex: number = (currentPage - 1) * upperLimit;
    const endIndex: number = startIndex + upperLimit;

    return (
        <div className='newsList'>
            {newsData.slice(startIndex, endIndex).map((news, index) => (
                <div key={index} title={news.title} content={news.content}className='newsItem' >
                    <p className='newsItemDate'>{news.date}</p>
                    <Link to={`/newsDetail/${news.newsNumber}`} className='newsItemTitle'>{news.title}</Link>
                    <p className='newsItemContent'>{news.content.replace(/\n/g, ' ')}</p>
                    <div className='newsItemImage'>
                        <div className='newsItemImageCenter'>
                            {news.image[0] && <img src={news.image[0]} alt={`お知らせ画像${index + 1}`} loading='lazy'/>}
                        </div>
                    </div>
                    <Link to={`/newsDetail/${news.newsNumber}`} className='readMoreButton'> &gt; 続きを読む</Link>
                </div>
            ))}
            {/* ページネーションリンクを追加 */}
            {showAll && <div className='pagination'>
                {Array.from({ length: newsListCount }, (_, i) => i + 1).map(page => (
                    <Link key={page} className={`paginationButton ${page === currentPage ? 'active' : ''}`} to={`/news/${page}`} onClick={() => setCurrentPage(page)}>{page}</Link>
                ))}
            </div>}
        </div>
    );
}

export default NewsContents