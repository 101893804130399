import React from 'react'
import { Routes, Route } from 'react-router-dom';

// import SlideShow from './PageContents/Component/SlideShow' いったん削除
import Introduction from './PageContents/Component/Introduction'
import NewsComponent from './PageContents/Component/NewsComponent'
import InstagramComponent from './PageContents/Component/InstagramComponent'

import Teacher from './PageContents/Teacher'
import Lesson from './PageContents/Lesson'
import News from './PageContents/News'
import NewsDetail from './PageContents/NewsDetail'
import Access from './PageContents/Access'
import Contact from './PageContents/Contact'

// ページのコンテンツ
const PageContents: React.FC = () => (
  <Routes>
    <Route path="/" element={<>
      <Introduction />
      <NewsComponent />
      <InstagramComponent />
    </>} />
    <Route path="/teacher" element={<>
      <Teacher />
    </>} />
    <Route path="/lesson" element={<>
      <Lesson />
    </>} />
    <Route path="/news/:index" element={<>
      <News />
    </>} />
    <Route path="/newsDetail/:index" element={<>
      <NewsDetail />
    </>} />
    <Route path="/access" element={<>
      <Access />
    </>} />
    <Route path="/contact" element={<>
      <Contact />
    </>} />
  </Routes>
)

export default PageContents