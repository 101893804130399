import React, { useEffect, useState } from 'react'
import FadeIn from './Component/FadeIn';
import { useParams } from 'react-router-dom';
import './NewsDetail.css'

const NewsDetail = () => {
    const { index } = useParams<{ index: string }>();
    const [newsData, setNewsData] = useState<{ newsNumber: number, date: string, title: string, content: string, image: string[], option: string }>();
    useEffect(() => {
        fetchSelectNewsData(Number(index)).then(data => setNewsData(data));
    }, [index]);
    async function fetchSelectNewsData(newsNumber: number): Promise<{ newsNumber: number, date: string, title: string, content: string, image: string[], option: string } | undefined> {
        try {
            const response: Response = await fetch(`${process.env.PUBLIC_URL}/news_20240422.json`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data: { newsNumber: number, date: string, title: string, content: string, image: string[], option: string }[] = await response.json();
            const selectNews: { newsNumber: number, date: string, title: string, content: string, image: string[], option: string } | undefined = data.find((news: { newsNumber: number }) => news.newsNumber === newsNumber);
            return selectNews;
        } catch (error) {
            console.error("Error fetching news data:", error);
        }
    }

    useEffect(() => {
        fetchSelectNewsData(Number(index)).then(data => setNewsData(data));
        const titleElement: HTMLElement | null = document.querySelector('.newsDetailTitle');
        if (titleElement) {
            const textLength: number = titleElement.textContent?.length || 0;
            titleElement.style.maxWidth = `${textLength * 36 + 48}px`;
        }
    }, [index, newsData?.title]);

    return (
        <FadeIn>
            <div className='newsDetail clearfix'>
                {newsData && (
                    <p className='newsDetailTitle'>
                        <img className='newsDetailTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="newsDetailTitleBackground" loading='lazy'/>
                        <img className='newsDetailTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category3.png'} alt="newsTitleLeft" loading='lazy'/>
                        {newsData.title}
                    </p>
                )}
                {newsData && newsData.option === "width-first" && (
                    <div className='newsDetailContainer'>
                        <p className='newsDetailContent' dangerouslySetInnerHTML={{ __html: newsData.content.replace(/\n/g, '<br />') }}></p>
                        <img className='newsDetailImage width-first' src={newsData.image[0]} alt='newsImage' loading='lazy'/>
                        <p className='newsDetailDate'>{newsData.date}</p>
                    </div>
                )}
                {newsData && newsData.option === "height-first" && (
                    <div className='newsDetailContainer'>
                        <img className='newsDetailImage height-first' src={newsData.image[0]} alt='newsImage' loading='lazy'/>
                        <p className='newsDetailContent' dangerouslySetInnerHTML={{ __html: newsData.content.replace(/\n/g, '<br />') }}></p>
                        <p className='newsDetailDate'>{newsData.date}</p>
                    </div>
                )}
                {newsData && newsData.option === "multiple-images" && (
                    <div className='newsDetailContainer'>
                        <p className='newsDetailContent' dangerouslySetInnerHTML={{ __html: newsData.content.replace(/\n/g, '<br />') }}></p>
                        <div className='multiple-images'>
                            {newsData.image.map((imageSrc, index) => (
                                <img key={index} className='newsDetailImage multiple-images' src={imageSrc} alt={`newsImage${index}`} loading='lazy'/>
                            ))}
                        </div>
                        <p className='newsDetailDate'>{newsData.date}</p>
                    </div>
                )}
                {newsData && newsData.option === "no-picture" && (
                    <div className='newsDetailContainer'>
                        <p className='newsDetailContent' dangerouslySetInnerHTML={{ __html: newsData.content.replace(/\n/g, '<br />') }}></p>
                        <p className='newsDetailDate'>{newsData.date}</p>
                    </div>
                )}
            </div>
        </FadeIn>
    )
}

export default NewsDetail