import React, { useEffect } from 'react'
import './InstagramWidget.css'

const InstagramWidget: React.FC = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = "https://cdn.lightwidget.com/widgets/lightwidget.js";
      script.async = true;
      document.body.appendChild(script);
    }, []);
  
    return (
      <iframe 
        src="https://cdn.lightwidget.com/widgets/f3da6b3dbda255018b8b2d8d6a72399d.html" 
        scrolling="no" 
        title="Instagram Widget"
        className="lightwidget-widget" 
      />
    );
  };

export default InstagramWidget