import React from 'react'
import FadeIn from './FadeIn';
import InstagramWidget from './InstagramWidget'
import './InstagramComponent.css'

const InstagramComponent: React.FC = () => (
    <FadeIn>
        <div className='instagramComponent'>
            <InstagramTitle />
            <InstagramWidget />
        </div>
    </FadeIn>
)

const InstagramTitle: React.FC = () => (
    <p className='instagramTitle'>
        <img className='instagramTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="instagramTitleBackground" />
        <img className='instagramTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category6.png'} alt="instagramTitleLeft" />
        Instagram
    </p>
)

export default InstagramComponent

