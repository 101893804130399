import React, { createContext, useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageHeaders from "./PageHeaders";
import PageContents from "./PageContents";
import PageFotters from "./PageFotters";
import PageAnimation from "./PageAnimation";

interface ScreenSizeInterface {
  width: number;
  height: number;
}
const screenSizeContext = {
  width: window.innerWidth,
  height: window.innerHeight
};

const tabButtonContext = {
  text1: "トップページ",
  text2: "先生紹介",
  text3: "レッスン紹介",
  text4: "お知らせ",
  text5: "アクセス",
  text6: "お問い合わせ",
  link1: "/",
  link2: "/teacher",
  link3: "/lesson",
  link4: "/news",
  link5: "/access",
  link6: "/contact"
};

const infoContext = {
  name: "くれっしぇんどピアノ教室",
  address: "〒410-0312 静岡県沼津市原",
  addressDetail1: "※自宅なので、お問い合わせしていただいた方に詳細な住所をお知らせします。",
  addressDetail2: "※原中、原小から徒歩10分以内の場所にあります。",
  representative: "田村 博美",
  tel: "お問い合わせしていただいた方にお知らせしています。",
};

const TabButtonContext = createContext(tabButtonContext);
const ScreenSizeContext = createContext(screenSizeContext);
const InfoContext = createContext(infoContext);

function App() {
  const [screenSize, setScreenSize] = useState(useContext(ScreenSizeContext));
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <HelmetProvider>
      <Router>
        <Helmet>
          <title>くれっしぇんどピアノ教室 | 静岡県沼津市原のピアノ教室です</title>
          <meta name="description" content="静岡県沼津市原のピアノ教室、くれっしぇんどピアノ教室の公式サイトです。教室の紹介や最新情報をお知らせします。" />
          <meta name="keywords" content="静岡県, 沼津市原, 沼津市, 原, ピアノ, ピアノ教室, くれっしぇんどピアノ教室, くれっしぇんど, クレッシェンドピアノ教室, クレッシェンド" />
        </Helmet>
        <ScrollToTop />
        <ScreenSizeContext.Provider value={screenSize}>
          <TabButtonContext.Provider value={tabButtonContext}>
            <InfoContext.Provider value={infoContext}>
              <PageAnimation />
              <PageHeaders />
              <PageContents />
              <PageFotters />
            </InfoContext.Provider>
          </TabButtonContext.Provider>
        </ScreenSizeContext.Provider>
      </Router>
    </HelmetProvider>
  );
}

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


export default App;

export { ScreenSizeContext, ScreenSizeInterface, TabButtonContext, InfoContext };
