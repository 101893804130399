import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import FadeIn from './FadeIn';
import './Introduction.css'

const Introduction: React.FC = () => {
  const [musicButtonSrc, setMusicButtonSrc] = useState<string>(process.env.PUBLIC_URL + '/picture/introduction/play_icon.png');
  const audioRef = useRef<HTMLAudioElement>(null);  // audio要素への参照
  const [precautions, setPrecautions] = useState<number>(0);

  const green_line: string = process.env.PUBLIC_URL + '/picture/introduction/green_line.png';
  const play_icon: string = process.env.PUBLIC_URL + '/picture/introduction/play_icon.png';
  const stop_icon: string = process.env.PUBLIC_URL + '/picture/introduction/stop_icon.png';

  // クリック時のハンドラー
  const playMusic = () => {
    if (audioRef.current) {
      if (musicButtonSrc === play_icon) {
        audioRef.current.play();  // 音楽を再生
        setMusicButtonSrc(stop_icon);
      } else {
        audioRef.current.pause();  // 音楽を停止
        audioRef.current.currentTime = 0;  // 再生位置を最初に戻す
        setMusicButtonSrc(play_icon);
      }
      setPrecautions(0);
    }
  };

  return (
    <div className='introduction'>
      <FadeIn>
        <div className='introductionTitleContainer'>
          <img className='introductionDecoration' src={green_line} alt="装飾" />
          <p className='introductionTitle'>{"ピアノを始めたら１０年続けてみよう！\nを合言葉にスタートした子供たちが"}</p>
          <p className='introductionTitle'>{"大人になってもピアノを楽しんでいます。"}</p>
          <p className='introductionTitle'>{"そんなピアノ教室です。"}</p>
          <img className='introductionDecoration' src={green_line} alt="装飾" />
        </div>
      </FadeIn>
      <div className='introductionComponent'>
        <FadeIn>
          <IntroductionItem link={'/teacher'} backImage_path={'back_red.png'} title={'先生紹介'} contentImage_path={'teacher.jpg'} decorationImage_path={'flowers_red.png'} decorationClass={'decoration_red'} text={'色々な活動をしています！'} />
        </FadeIn>
        <FadeIn>
          <IntroductionItem link={'/lesson'} backImage_path={'back_green.png'} title={'レッスン紹介'} contentImage_path={'lesson.jpg'} decorationImage_path={'flowers_green.png'} decorationClass={'decoration_green'} text={'こんなレッスンしています！'} />
        </FadeIn>
        <FadeIn>
          <IntroductionItem link={'/contact'} backImage_path={'back_blue.png'} title={'お問い合わせ'} contentImage_path={'contact.png'} decorationImage_path={'flowers_blue.png'} decorationClass={'decoration_blue'} text={'体験レッスンもこちらから！'} />
        </FadeIn>
      </div>
      <FadeIn>
        <div className='introductionMusic'>
          <p className='introductionMusicText'>{"モーツァルト"}</p>
          <p className='introductionMusicText'>{"ピアノソナタ K.545"}</p>
          <audio ref={audioRef} src={process.env.PUBLIC_URL + '/introductionMusic.m4a'} loop></audio>
          <img className='introductionMusicButton' src={musicButtonSrc} alt="ミュージックボタン" onClick={musicButtonSrc === play_icon ? () => setPrecautions(1) : playMusic} />
          <p className='introductionMusicText'>{"教室の生徒が編曲しました"}</p>
          <p className='introductionMusicText'>{"ボタンクリックで曲が流れます"}</p>
        </div>
      </FadeIn>
      <Precaution precautions={precautions} setPrecautions={setPrecautions} playMusic={playMusic} />
    </div>
  );
}


// ページ内リンクのコンポーネント
const IntroductionItem: React.FC<{ link: string, backImage_path: string, title: string, contentImage_path: string, decorationImage_path: string, decorationClass: string, text: string }> = ({ link, backImage_path, title, contentImage_path, decorationImage_path, decorationClass, text }) => (
  <div className='introductionItem'>
    <Link to={link}>
      <img className="introductionItemImage" src={process.env.PUBLIC_URL + '/picture/introduction/' + backImage_path} alt={`backImage_${link}`} />
      <img className={`introductionItemDecoration ${decorationClass}`} src={process.env.PUBLIC_URL + '/picture/introduction/' + decorationImage_path} alt={`decorationImage_${link}`} />
      <p className='itemTitle'>{title}</p>
      <img className="introductionItemContentImage" src={process.env.PUBLIC_URL + '/picture/introduction/' + contentImage_path} alt={`contentImage_${link}`} />
      <p className='itemText'>{text}</p>
    </Link>
  </div>
)


const Precaution: React.FC<{ precautions: number, setPrecautions: (status: number) => void, playMusic: () => void }> = ({ precautions, setPrecautions, playMusic }) => {
  return precautions === 0 ? null : (
    <div className='precaution'>
      <div className='precautionContainer'>
        <div>
          <p className='precautionText'>
            ※音楽が流れます。<br />問題なければ確認を押してください。
          </p>
          <div className='precautionButtonContainer'>
            <button className='precautionButton back' onClick={() => setPrecautions(0)}>戻る</button>
            <button className='precautionButton' onClick={() => playMusic()}>確認</button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Introduction