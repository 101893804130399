import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import FadeIn from './Component/FadeIn';
import './Contact.css';
import { ClipLoader } from "react-spinners";

const Contact: React.FC = () => {
    const [sendStatus, setSendStatus] = useState(0); // 送信の状態を管理する状態
    return (
        <div>
            <FadeIn>
                <div className='contact'>
                    <ContactTitle />
                    <p className='contactText'>ピアノ教室についてのご質問や、体験レッスンのお申し込み等<br />お問い合わせはこちらからお願いします。<br />24時間受付しています!</p>
                    <ContactForm setSendStatus={setSendStatus} />
                </div>
            </FadeIn>
            <SendProcess sendStatus={sendStatus} setSendStatus={setSendStatus} />
        </div>
    )
}

const ContactTitle: React.FC = () => {
    return (
        <p className='contactTitle'>
            <img className='contactTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="contactTitleBackground" />
            <img className='contactTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category5.png'} alt="contactTitleLeft" />
            お問い合わせ
        </p>
    );
}

const ContactForm: React.FC<{ setSendStatus: (status: number) => void }> = ({ setSendStatus }) => {
    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [lessonTaker, setLessonTaker] = useState<string>('');
    const [age, setAge] = useState<number | undefined>(undefined);
    const [pianoExperience, setPianoExperience] = useState<string>('');
    const registry = 'pianocresc.com'
    const url = `https://${registry}/api/send_mail`;
    // const url = 'http://localhost:3001/send_mail';

    const isFormValid = name.trim() !== '' && email.trim() !== '' && title.trim() !== '' && message.trim() !== '';
    const isFormValidTrial = lessonTaker.trim() !== '' && age !== undefined && pianoExperience.trim() !== '';

    const handleSubmit = async (event: FormEvent) => {
        try {
            event.preventDefault();
            if (!isFormValid) return; // フォームが有効でない場合は送信を阻止
            if (title === "体験レッスンのお申し込み" && !isFormValidTrial) return; // 体験レッスン指定時、フォームが有効でない場合は送信を阻止
            // ここでメール送信の処理を行います
            const formData = {
                name: name,
                email: email,
                phone: phone,
                title: title,
                message: message,
                lesson_taker: lessonTaker,
                age: age,
                piano_experience: pianoExperience
            };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            console.log(JSON.stringify(formData));
            if (response.ok) {
                console.log("OK");
                setSendStatus(2);
                setName('');
                setEmail('');
                setPhone('');
                setTitle('');
                setMessage('');
                setLessonTaker('');
                setAge(undefined);
                setPianoExperience('');
            } else {
                console.log("NG");
                setSendStatus(3);
            }
        } catch (error) {
            console.error('エラーが発生しました:', error);
            // ユーザーにエラーを通知するためのコードをここに追加
        }
    };

    return (
        <form onSubmit={handleSubmit} className={title === "体験レッスンのお申し込み" ? "contactForm" : "contactForm contactFormAdjust"}>
            <label className='contactLabel'>
                お名前<span className='contactRequired'>*</span>
                <input type="text" value={name} onChange={
                    (e: ChangeEvent<HTMLInputElement>) => {
                        setName(e.target.value);
                    }}
                    required className='contactInput' />
            </label>

            <label className='contactLabel contactEmail'>
                メールアドレス<span className='contactRequired'>*</span>
                <input type="email" value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} required className='contactInput' />
                <p className='contactInputText'>※正しいメールアドレスでない場合、返信ができません。</p>
            </label>
            <label className='contactLabel'>
                電話番号
                <input type="tel" value={phone} onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)} className='contactInput' />
                <p className='contactInputText'>※ハイフンは含まなくてもよいです。</p>
            </label>
            <label className='contactLabel'>
                お問い合わせ項目<span className='contactRequired'>*</span>
                <select
                    value={title}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                        setTitle(e.target.value);
                        if (e.target.value === "体験レッスンのお申し込み" && lessonTaker === '') {
                            setLessonTaker(name);
                        }
                    }}
                    required
                    className={title === '' ? 'contactSelect placeholder' : 'contactSelect'}
                >
                    <option value="" style={{ color: 'gray' }}>選択してください</option>
                    <option value="ご相談・ご質問">ご相談・ご質問</option>
                    <option value="体験レッスンのお申し込み">体験レッスンの申し込み</option>
                    <option value="その他">その他</option>
                </select>
            </label>
            <p className={title === "体験レッスンのお申し込み" ? "contactTrialText contactTrialTextVisible" : "contactTrialText"}>体験レッスンを受ける方の情報</p>
            <label className={title === "体験レッスンのお申し込み" ? "contactLabel contactVisible" : "contactLabel"} style={{ opacity: title === "体験レッスンのお申し込み" ? "1" : "0" }}>
                お名前
                <span className='contactRequired'>*</span>
                <input type="text" value={lessonTaker} onChange={(e: ChangeEvent<HTMLInputElement>) => setLessonTaker(e.target.value)} required={title === "体験レッスンのお申し込み"} className='contactInput' />
            </label>
            <div className={title === "体験レッスンのお申し込み" ? "contactRow contactVisible" : "contactRow"}>
                <label className='contactLabel'>
                    年齢<span className='contactRequired'>*</span>
                    <div className="contactLabelRow">
                    <input type="number" min="0" value={age || ''} onChange={(e: ChangeEvent<HTMLInputElement>) => setAge(Number(e.target.value))} required={title === "体験レッスンのお申し込み"} className='contactInput' style={{ display: 'inline-block', width: '70px' }} /> 歳
                    </div>
                </label>
                <label className='contactLabel' style={{ width: '200px' }}>
                    ピアノの経験<span className='contactRequired'>*</span>
                    <select
                        value={pianoExperience}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setPianoExperience(e.target.value);
                        }}
                        required={title === "体験レッスンのお申し込み"}
                        className={pianoExperience === '' ? 'contactSelect placeholder' : 'contactSelect'}
                    >
                        <option value="" style={{ color: 'gray' }}>選択してください</option>
                        <option value="なし">なし</option>
                        <option value="1年未満">1年未満</option>
                        <option value="1年以上">1年以上</option>
                        <option value="5年以上">5年以上</option>
                    </select>
                </label>
            </div>
            <label className={title === "体験レッスンのお申し込み" ? 'contactLabel contactTextareaMoving' : 'contactLabel contactTextareaStatic'}>
                お問い合わせ内容<span className='contactRequired'>*</span>
                <textarea value={message} onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)} required className='contactTextarea' />
                <p className='contactInputText'><span className='contactRequired'>*</span>は必須項目です。</p>
            </label>
            <button disabled={title === "体験レッスンのお申し込み" ? !isFormValidTrial || !isFormValid : !isFormValid} onClick={() => setSendStatus(1)} className={title === "体験レッスンのお申し込み" ? 'contactSubmit contactSubmitMoving' : 'contactSubmit'} type="submit">送信</button>
        </form>
    );
}

const SendProcess: React.FC<{ sendStatus: number, setSendStatus: (status: number) => void }> = ({ sendStatus, setSendStatus }) => {
    const [dotsCount, setDotsCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDotsCount((prevCount) => (prevCount + 1) % 4);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return sendStatus === 0 ? null : (
        <div className='sendProcess'>
            <div className='sendProcessContainer'>
                {sendStatus === 1 ? (
                    <div>
                        <ClipLoader color={"#000"} loading={true} size={150} />
                        <p className='sendProcessText'>送信中{Array(dotsCount).fill('.').join('')}</p>
                    </div>
                ) : sendStatus === 2 ? (
                    <div>
                        <p className='sendProcessText'>
                            お問い合わせありがとうございます。<br />
                            送信が完了しました！<br />
                            ご返信までしばらくお待ちください。
                        </p>
                        <button onClick={() => setSendStatus(0)} className='sendProcessButton'>確認</button>
                        {/* 送信が完了したことを示すマークを表示するコード */}
                    </div>
                ) : (
                    <div>
                        <p className='sendProcessText'>
                            送信に失敗しました。もう一度お試しください。<br />
                            それでも解決しない場合、数日後に再度ご連絡ください。
                        </p>
                        <button onClick={() => setSendStatus(0)} className='sendProcessButton'>確認</button>
                        {/* 送信が失敗したことを示すマークを表示するコード */}
                    </div>
                )}
            </div>
        </div>
    );
}


export default Contact;