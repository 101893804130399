import React from 'react'
import FadeIn from './Component/FadeIn';
import InstagramWidget from './Component/InstagramWidget'
import './Teacher.css'

const Teacher = () => (
  <FadeIn>
    <div className='teacher'>
      <TeacherTitle />
      <Greeting />
      <TeacherInstagram />
    </div>
  </FadeIn>
)

const TeacherTitle: React.FC = () => (
  <p className='teacherTitle'>
    <img className='teacherTitleBackground' src={process.env.PUBLIC_URL + '/picture/base/background_title.png'} alt="teacherTitleBackground" />
    <img className='teacherTitleLeft' src={process.env.PUBLIC_URL + '/picture/base/category1.png'} alt="teacherTitleLeft" />
    先生紹介
  </p>
)
// 挨拶のコンポーネント
const Greeting: React.FC = () => (
  <div className='greeting'>
    <img className="greetingImageLeft" src={process.env.PUBLIC_URL + '/picture/teacher/main_picture.jpg'} alt="講師挨拶画像" />
    <div>
      <p className='greetingText'>はじめまして、くれっしぇんどピアノ教室の田村ひろみと申します。</p>
      <p className='greetingText'>大人になっても音楽って楽しいな、と思ってもらえるようなレッスンをしています。どうぞよろしくお願いします。</p>
    </div>
    <img className="greetingImageUnder" src={process.env.PUBLIC_URL + '/picture/teacher/main_picture.jpg'} alt="講師挨拶画像" />
  </div>
)

const TeacherInstagram: React.FC = () => (
  <div className='teacherInstagram'>
    <p className='teacherInstagramText'>教室の様子、私の音楽活動等お知らせをInstagramで発信しています。</p>
    <p className='teacherInstagramText'>こちらもご覧ください。</p>
    <InstagramWidget />
  </div>
)

export default Teacher